import md5 from 'js-md5'
import store from "@/store";

export function getSingString(data){
    let str = ''
    for (const key in data){
        let value = data[key];
        if (value === null || value === undefined) {
            continue
        }
        if (typeof value == 'object') {
            value = getSingString(value)
        }
        str = str + value
    }
    return str

}

export function makeSign(inObject) {
    delete inObject.time;
    delete inObject.sign;
    inObject.time = getTime();
    const sorter = function (inObject) {
        const sortedJson = {};
        const sortedKeys = Object.keys(inObject).sort();
        for (let i = 0; i < sortedKeys.length; i++) {
            sortedJson[sortedKeys[i]] = inObject[sortedKeys[i]]
        }
        return sortedJson;
    }
    const sortedParam = sorter(inObject);
    let needSignatureStr = getSingString(sortedParam);
    const key = process.env.VUE_APP_SIGN_KEY;
    return md5(md5(needSignatureStr + key) + key);
}

function getTime() {
    let date = new Date();   //将时间格式转为字符串
    return Date.parse(date.toString()) / 1000
}
export async function getUserInfo() {
    if (store.state.userInfo.length === 0 || Object.keys(store.state.userInfo).length) {
        await store.dispatch('getUserInfo')
    }
    return Object.keys(store.state.userInfo).length;
}

export async function getStsToken() {
    if (store.state.stsToken.AccessKeyId && (getTime() + 300) < store.state.stsToken.Expiration) {
        return store.state.stsToken
    }else{
        await store.dispatch('getStsToken')
    }
    return store.state.stsToken

}

export function authCheckRouter(arrObj){
    const userLevel = store.state.userInfo.promote_level
    const filter = [108,169,91,333,12,16,1618,1119,1752]
    let newArrObj =  arrObj.filter((data)=>{
        return !data.auth || (data.auth && userLevel == data.auth) && (!data?.filter || (data?.filter && !(filter.includes(store.state.userInfo.id))))
    })
    return newArrObj;
}

