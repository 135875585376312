// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2022/9/8
// +-------------------------------------------------------------
// |Time: 10:23
// +-------------------------------------------------------------
export default [
    {
        path: "/recharge/bind-balance",
        name: "rechargeBindBalance",
        meta: {
            title: "玩家代充",
            menu:true,
        },
        component: () => import("@/views/recharge/bindBalance/Index.vue")
    },
    {
        path: "/recharge/bind-balance-record",
        name: "rechargeBindBalanceRecord",
        meta: {
            title: "代充记录",
            menu:true,
        },
        component: () => import("@/views/recharge/bindBalance/Record.vue")
    },
    {
        path: "/recharge/auto-balance",
        name: "autoRechargeBalance",
        meta: {
            title: "扫码加币",
            menu:true,
        },
        component: () => import("@/views/recharge/autoBalance/Index.vue")
    },
    {
        path: "/recharge/auto-balance-record",
        name: "autoRechargeBalanceRecord",
        meta: {
            title: "加币订单",
            menu:true,
        },
        component: () => import("@/views/recharge/autoBalance/Record.vue")
    },
    {
        path: "/recharge/send-balance-record",
        name: "sendBalanceRecord",
        meta: {
            title: "平台币记录",
            menu:true,
        },
        component: () => import("@/views/recharge/sendBalanceRecord.vue")
    }
]
